import { useCallback, useState } from "react";
import { useEnqueueSnackbar } from "../useEnqueueSnackbar";
import ScheduleReportService from "../../services/ScheduleReportService";
import apiClient from "../../auth/apiClient";

const scheduleReportService = new ScheduleReportService(apiClient);

export default function useDefaultOfDayWeek(facilityID) {
  const enqueueSnackbar = useEnqueueSnackbar();
  const [defaultOfDayWeek, setDefaultOfDayWeek] = useState(null);

  const fetchDefaultOfDayWeek = useCallback(async () => {
    try {
      const response = await scheduleReportService.getStartOfDayWeek(facilityID);

      if (!response || !response.data) {
        enqueueSnackbar("No default of Day/Week available", {
          variant: "error",
          tag: "NoDefaultOfDayWeekAvailable",
        });
        return;
      }

      let payload;
      try {
        payload = JSON.parse(response.data.payload);
      } catch (parseError) {
        enqueueSnackbar("Invalid data format for StartOfDayWeek", {
          variant: "error",
          tag: "InvalidDataFormat",
        });
        return;
      }

      const { StartOfDay } = payload;

      if (StartOfDay) {
        setDefaultOfDayWeek(`0001-01-01T${StartOfDay}:00`);
      } else {
        enqueueSnackbar("StartOfDay is missing", {
          variant: "error",
          tag: "StartOfDayMissing",
        });
      }
    } catch (error) {
      enqueueSnackbar("Unable to retrieve default of Day/Week", {
        variant: "error",
        tag: "UnableToRetrieveDefaultOfDayWeek",
      });
    }
  }, [facilityID, enqueueSnackbar]);

  return {defaultOfDayWeek, fetchDefaultOfDayWeek };
}
