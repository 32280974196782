import PropTypes from "prop-types";
import {
  GetEntityTreeWithNoState,
  GetAllScopedPermissions,
  FindNearestFacilityFromEntity,
  FindNearestFacilityGroupFromEntity
} from "../state/slices/entities";
import { useSelector, shallowEqual } from "react-redux";
import useCurrentFacility from "../hooks/useCurrentFacility";
import {
  selectAllScopedPermissions,
  findNearestFacilityFromEntity as coreFindNearestFacilityFromEntity,
  findNearestFacilityGroupFromEntity as coreFindNearestFacilityGroupFromEntity,
  selectEntitiesWithNoState
} from "../state/slices/CoreEntity";
import { useCoreEntityContext } from "./useCoreEntitySlice";
import {selectAllOrgScopedPermissions, selectOrgEntitiesWithNoState} from "../state/slices/OrgTree";

/**
 * @param  {string} scope The current scope you are checking permissions under; default null
 */
const useHasPermissions = () => {
  const { facilityID: currentFacility } = useCurrentFacility();
  const useCoreEntitySlice = useCoreEntityContext();
  const facilityGroupId = useSelector((state) => state.entityScope?.facilityGroupId);
  const strippedEntities = useSelector(
    state =>{
      let strippedEntities = GetEntityTreeWithNoState(state?.entities?.EntityList)
      if(useCoreEntitySlice){
        strippedEntities = selectEntitiesWithNoState(state)
      }
      return strippedEntities
    }
  ,(one, two) => JSON.stringify(one) === JSON.stringify(two));


  const orgTreeStrippedEntities = useSelector(state => {
    return useCoreEntitySlice ? selectOrgEntitiesWithNoState(state) : null;
  }, shallowEqual);

  const scopedPermissions = useSelector(state => {
    return useCoreEntitySlice ? selectAllScopedPermissions(state) : GetAllScopedPermissions(state?.entities?.EntityList);
  }, shallowEqual);

  const orgTreeScopedPermissions = useSelector(state => {
    return useCoreEntitySlice ? selectAllOrgScopedPermissions(state): null;
  }, shallowEqual);

  const hasPermissions = (neededPermissions, hasAny, scope = null) => {
    let currentScope = currentFacility;
    if (scope) {
      if (scope === facilityGroupId) {

        currentScope = useCoreEntitySlice ? coreFindNearestFacilityGroupFromEntity(strippedEntities, scope)?.entityId : FindNearestFacilityGroupFromEntity(strippedEntities, scope)
          ?.entityid;
      } else {
        currentScope = useCoreEntitySlice ? coreFindNearestFacilityFromEntity(strippedEntities, scope)?.entityId : FindNearestFacilityFromEntity(strippedEntities, scope)
          ?.entityid;
        // didn't find one - could it be a FG
        if (!currentScope) {
          currentScope = useCoreEntitySlice ? coreFindNearestFacilityGroupFromEntity(strippedEntities, scope)?.entityId : FindNearestFacilityGroupFromEntity(strippedEntities, scope)?.entityid;
        }
      }

      if(useCoreEntitySlice && !currentScope){
        currentScope = useCoreEntitySlice ? coreFindNearestFacilityFromEntity(orgTreeStrippedEntities, scope)?.entityId : FindNearestFacilityFromEntity(orgTreeStrippedEntities, scope)
            ?.entityid;
        // didn't find one - could it be a FG
        if (!currentScope) {
          currentScope = useCoreEntitySlice ? coreFindNearestFacilityGroupFromEntity(orgTreeStrippedEntities, scope)?.entityId : FindNearestFacilityGroupFromEntity(orgTreeStrippedEntities, scope)?.entityid;
        }
      }
      // they don't have this scope in their context tree at all
      if (!currentScope) return false;
    }


    let currentPermissions = scopedPermissions[currentScope] ?? (useCoreEntitySlice ?  orgTreeScopedPermissions[currentScope] : null);

    if (facilityGroupId && currentPermissions) currentPermissions = [...currentPermissions, scopedPermissions[facilityGroupId]];

    if (!currentPermissions) {
      return false;
    }

    if (hasAny === true) {

      for (let i = 0; i < neededPermissions?.length; i++) {

        if (currentPermissions.includes(neededPermissions[i])) return true;
      }
      return false;
    } else {
      for (let i = 0; i < neededPermissions?.length; i++) {
        if (!currentPermissions.includes(neededPermissions[i])) {
          return false;
        }
      }
      return true;
    }
  };

  return { hasPermissions };
};

useHasPermissions.defaultProps = {
  scope: null
};

useHasPermissions.propTypes = {
  scope: PropTypes.string
};

export default useHasPermissions;
