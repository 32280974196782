import { fetchRequestsByEntityID } from "../../state/slices/requestmonitor";
import { useDispatch } from "react-redux";

const useRequestMonitorListeners = () => {
  const dispatch = useDispatch();

  const getEntityRequestMonitorData = (entityID) => {
    dispatch(fetchRequestsByEntityID(entityID));
  };

  return { getEntityRequestMonitorData };
};

export default useRequestMonitorListeners;
