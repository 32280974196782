import { useState, useEffect, useCallback } from "react";
import { useEnqueueSnackbar } from "../useEnqueueSnackbar";
import ScheduledReportsService from "../../services/ScheduledReportsService";
import apiClient from "../../auth/apiClient";

const scheduledReportsService = new ScheduledReportsService(apiClient);

export default function useScheduledReportsOptions() {
  const enqueueSnackbar = useEnqueueSnackbar();
  const [defaultreports, setDefaultReports] = useState([]);
  const [reporttypes, setReportTypes] = useState([]);
  const [reportperiod, setReportPeriods] = useState([]);
  const [sendday, setSendDays] = useState([]);
  const [exportformat, setExportFormats] = useState([]);

  const getOptions = useCallback(async () => {
    try {
      const response = await scheduledReportsService.getOptionsForScheduledReports();
      if (response) {
        const defaultReportsData = response?.data?.defaultReports || [];
        defaultReportsData.sort((a, b) =>
          a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
        );
        setDefaultReports(defaultReportsData);
        setReportTypes(response?.data?.reportTypes || []);
        setReportPeriods(response?.data?.reportPeriods || []);
        setSendDays(response?.data?.sendDays || []);
        setExportFormats(response?.data?.exportFormats || []);
      } else {
        enqueueSnackbar("No options available", {
          variant: "error",
          tag: "NoOptionsAvailable",
        });
      }
    } catch (error) {
      enqueueSnackbar("Unable to retrieve options", {
        variant: "error",
        tag: "UnableToRetrieveOptions",
      });
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    getOptions();
  }, []);

  return { defaultreports, reporttypes, reportperiod, sendday, exportformat };
}
